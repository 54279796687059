/* eslint-disable react/no-unescaped-entities */

'use client';

import Stack from '@mui/material/Stack';
import { Box, Container, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import CopyScript from 'src/components/copy-script/copy-script';
import Link from 'src/components/link/link';
import PropTypes from 'prop-types';
import { HOST_API } from 'src/config-global';
import { Image } from 'src/components/landing';
import OnboardingReadMore from 'src/components/onboarding/partials/onboarding-read-more';
import InstallationStep from './installation-step';
import InstallationHeader from './installation-header';
import { InstallationSidebarCompliance, InstallationSidebarWantToKnowMore } from './installation-sidebar-content';
import { truncate } from '../../../utils/format-string';
import InstallationFinalStep from './installation-final-step';

// ----------------------------------------------------------------------

export default function InstallationGTM({
                                          trackingTag,
                                          onNext,
                                          onPrevious,
                                          noPadding,
                                          onRefresh,
                                          eventCount,
                                          webhookCount,
                                          dnsStatus,
                                          isDataRequiredToContinue,
                                        }) {
  const handleComplete = () => {
    if (onNext) {
      onNext();
      return;
    }
    if (window.location.pathname === `/dashboard/tracking/${trackingTag?.id}/details/?ready=1`) {
      window.location.reload();
      return;
    }
    window.location.href = `/dashboard/tracking/${trackingTag?.id}/dashboard?ready=1`;
  };

  const trackingId = trackingTag?.key;
  const trackingDomain = trackingTag?.domain;
  const trackingFallbackDomain = String(trackingTag?.fallback_domain || HOST_API)
    .replace('https://', '')
    .replace('http://', '');

  const imageSx = {
    // boxShadow: 'rgba(145, 158, 171, 0.08) 0px 10px 30px 0px',
    border: '1px solid rgb(213 217 221)',
    borderRadius: 1.5,
    '& img': {
      m: -0.125,
      width: 'calc(100% + 4px)',
      maxWidth: 'calc(100% + 4px)',
      height: 'calc(100% + 4px)',
    },
  };

  const renderInstallation = (
      <Container maxWidth="xl" sx={{ ...(noPadding && { px: { xs: 0, md: 0 } }) }}>
        <Stack
          spacing={1}
          sx={{
            justifyContent: 'center',
            minHeight: { xs: noPadding ? 0 : '55vh', md: noPadding ? 0 : '70vh' },
            pt: { xs: 3.5, md: noPadding ? 4 : 12 },
            pb: 5,
          }}
        >
          <InstallationHeader
            trackingTag={trackingTag}
            installationMethod="gtm"
            icon={<Iconify icon="logos:google-tag-manager" width={96} />}
            title={
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.1,
                  mb: 0.5,
                }}
              >
                Google Tag Manager Installation
              </Typography>
            }
            description={
              <Typography variant="body1">
                Follow these steps to install Tracklution to <Box component="span"
                                                                  sx={{ fontWeight: 600 }}>{truncate(trackingTag?.title || 'your webiste', 30)}</Box>.
              </Typography>
            }
            onPrevious={onPrevious}
            noPadding={noPadding}
          />
          <Stack sx={{ px: 0, py: 3, pl: { xs: 0, md: noPadding ? 0 : 3 } }}>
            <InstallationStep
              stepTitle="Step 1"
              title="Implement the Main Script and PageView"
              help={[
                <Stack>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    What are the benefits?
                  </Typography>
                  <Typography variant="body2">
                    Our GTM users experience on average{' '}
                    <Box component="span" sx={{ color: 'accent.main', fontWeight: 500 }}>
                      +32.1 % improved conversions{' '}
                    </Box>.
                  </Typography>
                </Stack>,
                ...InstallationSidebarWantToKnowMore('/installation/gtm-installation'),
              ]}
              isFirst
              noPadding={noPadding}
            >
              <Typography variant="body1" gutterBottom>
                Open GTM and add a new tag ("Custom HTML"). Copy and paste the script below to the
                HTML field.
              </Typography>

              <CopyScript
                description="Main script"
                alias="gtm-main-script"
                rows={10}
                script={`<script>
  !function(f,b,e,v,n,t,s)
  {if(f.tlq)return;n=f.tlq=function(){n.callMethod?
    n.callMethod(arguments):n.queue.push(arguments)};
    if(!f._tlq)f._tlq=n;n.push=n;n.loaded=!0;n.version='1.0';n.src=v;
    n.queue=[];t=b.createElement(e);t.async=!0;n.pd = false;n.tools = null;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s);t.onerror=function(){
    v='https://${trackingFallbackDomain}/js/script-dynamic.js?version=${+Date.now()}';
    f._tlq.src=v;t=b.createElement(e);t.async=!0;t.src=v;
    s.parentNode.insertBefore(t, s)
  }}(window,document,'script',
    'https://${trackingDomain}/js/script-dynamic.js?version=${+Date.now()}');
  
  tlq('init', '${trackingId}');
  tlq('track', 'PageView');
</script>`}
                sx={{ mb: 2 }}
                descriptionSx={{ fontWeight: 600, mt: 0.5 }}
              />

              <Typography variant="body1" gutterBottom>
                <Box component="span" sx={{ fontWeight: 600 }}>
                  Triggering:
                </Box>{' '}
                This tag should be loaded on all pages and before other tags. The trigger is typically
                named something like “All Pages,” though the exact name may vary depending on your GTM
                configuration.
              </Typography>

              <OnboardingReadMore
                title="EU Area: Support for Consent Mode"
                sx={{ mb: 0 }}
                typographySx={{ fontWeight: 600, fontSize: '0.95rem' }}
              >
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  If you have integrated your cookie banner into the built-in Consent Mode in GTM,
                  Tracklution reads the cookie consent status from the data layer and adapts to the
                  cookie consent status automatically. This is enabled by default, so no action is
                  required from you.
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  If you do not use GTM’s built-in Consent Mode, you can also integrate consents
                  manually:{' '}
                  <Link
                    href="https://docs.tracklution.com/installation/cookie-compatibility"
                    target="_blank"
                  >
                    Read documentation
                  </Link>
                </Typography>
              </OnboardingReadMore>

              <Typography variant="body1" sx={{ mt: 1 }}>
                Grrreat, now you have the basics done! Let's do this one more time. The next step
                collects the actual conversion events, so it is super important for you!
              </Typography>
            </InstallationStep>

            <InstallationStep
              stepTitle="Step 2"
              title="Implement Purchase event"
              help={[
                <Stack>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Do you need help?
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    On the bottom right corner, you can chat with our support team.
                  </Typography>
                </Stack>,
                ...InstallationSidebarCompliance({ mb: -40 }),
              ]}
              noPadding={noPadding}
            >
              <Typography variant="body1" gutterBottom>
                Create a new tag (“Custom HTML”). Copy and paste the script below to the HTML field.
              </Typography>

              <CopyScript
                description="Purchase event script"
                alias="gtm-purchase-event-script"
                rows={14}
                script={`<script>
  tlq('track', 'Purchase', {
    value: 75.10, 
    currency: 'EUR'
  });
</script>

<script>
  tlq('set', 'ContactInfo', { 
    email: 'example@email.com',
    phoneNumber: '+358501234567'
  });
</script>`}
                sx={{ mb: 2 }}
                descriptionSx={{ fontWeight: 600, mt: 0.5 }}
              />

              <OnboardingReadMore
                title="Install other event than Purchase as the main conversion event"
                sx={{ mb: 0 }}
                typographySx={{
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  textAlign: 'left',
                  lineHeight: 1.5,
                }}
              >
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  Great! Most probably you want to configure a Lead event instead. Use the script
                  below, and then just continue following this guidance. If you want to track
                  something else than a Lead event, e.g. any custom event, just replace the name
                  ‘Lead’ with the preferred event name inside the script.
                </Typography>

                <CopyScript
                  description="Lead event script"
                  alias="gtm-lead-event-script"
                  rows={11}
                  script={`<script>
  tlq('track', 'Lead');
</script>

<script>
  tlq('set', 'ContactInfo', { 
    email: 'example@email.com',
    phoneNumber: '+358501234567'
  });
</script>`}
                  sx={{ mb: 2 }}
                  descriptionSx={{ fontWeight: 600, mt: 0.5 }}
                />
              </OnboardingReadMore>

              <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2 }}>
                Replace example values with correct variables
              </Typography>
              <Typography variant="body1">
                Replace the example values in the script (e.g. value and currency parameters) with the
                actual variables from your GTM data layer to catch the purchase information correctly.
                See screenshot below for reference.
              </Typography>
              <Box component="ol">
                <Typography variant="body1" component="li" gutterBottom sx={{ pl: 1 }}>
                  Remove the example Purchase value (75.10) and start typing <code>{'{{'}</code>. You
                  will see a dropdown menu where you find the Data Layer Variables. Choose the correct
                  variable for Purchase value.
                </Typography>

                <Typography variant="body1" component="li" gutterBottom sx={{ pl: 1 }}>
                  Change the currency the same way if you handle multiple currencies, or just type the
                  fixed currency code (e.g. USD) in case of a single currency site.
                </Typography>

                <Typography variant="body1" component="li" gutterBottom sx={{ pl: 1 }}>
                  Ensure you don’t accidentally remove the single quotation marks (‘) around the
                  currency variable and other string fields (all the text fields).
                </Typography>
              </Box>

              <Image
                src="https://api.tracking.leadspark.io/appfiles/upload/default/1/9ea67cef-2018-43da-8437-14c1e5a13245-gtm-datalayer-variable.jpg"
                sx={{
                  mb: 4,
                  ...imageSx,
                }}
              />

              <OnboardingReadMore
                title="What are data layer variables?"
                sx={{ mb: 2 }}
                typographySx={{
                  fontWeight: 600,
                  textAlign: 'left',
                  lineHeight: 1.5,
                }}
              >
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  To track conversions with their actual value, you need to have the proper variables
                  in the GTM. Go to “Variables'' and check if you have the needed variables, such as
                  currency and purchase value. If not, you have to create them. For example, the
                  variables might look like this:
                </Typography>

                <Image
                  src="https://docs.tracklution.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fgtm-dlv-example.d5208539.png&w=640&q=75"
                  sx={{
                    ...imageSx,
                  }}
                />

                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                  How to create a custom variables in GTM:{' '}
                  <Link target="_blank" href="https://support.google.com/tagmanager/topic/9125128">
                    https://support.google.com/tagmanager/topic/9125128
                  </Link>
                </Typography>
              </OnboardingReadMore>

              <OnboardingReadMore
                title="Tracking Enhanced Conversions (email, phone)"
                sx={{ mb: 2 }}
                typographySx={{
                  fontWeight: 600,
                  textAlign: 'left',
                  lineHeight: 1.5,
                }}
              >
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  Implementing ContactInfo isn't strictly necessary but highly recommended to maximise
                  your server-side tracking benefits. Here's why:
                </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  <Box component="span" sx={{ fontWeight: 600 }}>
                    Triggering:
                  </Box>{' '}
                  Unlock capabilities like Enhanced Conversions (Google Ads) and Advanced Matching
                  (Meta). This includes cross-channel remarketing without relying on third-party
                  cookies, capturing otherwise lost conversions (e.g., from missing click IDs),
                  identifying returning users, and enhancing marketing campaign data for better
                  performance.
                </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  <Box component="span" sx={{ fontWeight: 600 }}>
                    Integrate External Data:
                  </Box>{' '}
                  Easily incorporate data from other systems, such as tracking offline conversions,
                  through efficient matching via Webhook delivery to Tracklution.
                </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  <Box component="span" sx={{ fontWeight: 600 }}>
                    Your Data Remains Yours:
                  </Box>{' '}
                  As a technology provider, we emphasise that your data is exclusively yours. Our
                  access is strictly confined to what's necessary for delivering server-side tracking,
                  without any claim to your data.
                </Typography>

                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                  There are also other data fields available than the ones mentioned in this
                  onboarding guide. For further information, see detailed instructions here:{' '}
                  <Link
                    target="_blank"
                    href="https://docs.tracklution.com/installation/gtm-installation"
                  >
                    Read more from Tracklution Documentation
                  </Link>
                </Typography>
              </OnboardingReadMore>

              <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2 }}>
                Correct tag sequencing
              </Typography>

              <Typography variant="body1" gutterBottom>
                Open “Advanced Settings” and click “Tag Sequencing”.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Choose the “Fire a tag before [purchase tag] fires”, and in the “Set-up Tag” dropdown,
                choose the Tracklution main script tag you created in the first step.
              </Typography>

              <Image
                src="https://docs.tracklution.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fgtm-sequencing.2ece1bae.png&w=750&q=75"
                sx={{
                  mt: 1,
                  ...imageSx,
                }}
              />

              <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2 }}>
                Add a correct trigger
              </Typography>

              <Typography variant="body1" gutterBottom>
                Add a correct Trigger for your Tag (usually something related to purchase):
              </Typography>

              <Image
                src="https://docs.tracklution.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fgtm-triggering.667cc144.png&w=750&q=75"
                sx={{
                  my: 1,
                  ...imageSx,
                }}
              />

              <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
                Missing triggers?{' '}
                <Link
                  target="_blank"
                  href="https://support.google.com/tagmanager/answer/7679316"
                  sx={{ whiteSpace: 'wrap' }}
                >
                  Learn how to create triggers in GTM.
                </Link>
              </Typography>

              <Typography variant="body1">As the last step, remember to save the tag!</Typography>
            </InstallationStep>

            <InstallationStep
              stepTitle="Step 3"
              title="Implementing any other event"
              help={[
                <Stack>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Do you need help?
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    On the bottom right corner, you can chat with our support team.
                  </Typography>
                </Stack>,
              ]}
              noPadding={noPadding}
            >
              <Typography variant="body1" gutterBottom>
                To implement any other events, create a new tag in GTM ( “Custom HTML”) and copy-paste
                the script below as you did in the previous steps.
              </Typography>

              <Typography variant="body1" gutterBottom>
                In this example we will implement an AddToCart event.
              </Typography>

              <CopyScript
                description="AddToCart event script"
                alias="gtm-add-to-cart-event-script"
                rows={4}
                script={`<script>
  tlq('track', 'AddToCart');
</script>`}
                sx={{ mb: 2 }}
                descriptionSx={{ fontWeight: 600, mt: 0.5 }}
              />

              <Typography variant="body1" gutterBottom>
                Ensure correct tag sequencing: Open “Advanced Settings” and click “Tag Sequencing”.
                Choose the “Fire a tag before [this tag] fires”, and in the “Set-up Tag” dropdown,
                choose the Tracklution main script tag you created in the first step.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Choose the correct Trigger.
              </Typography>

              <Image
                src="https://docs.tracklution.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fgtm-addtocart.a05bad39.png&w=1920&q=75"
                sx={{
                  my: 1,
                  ...imageSx,
                }}
              />

              <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
                You did it! Now you can just repeat this step until you have configured all the events
                you want to track. You don’t have to do them now, but it’s always good to repeat the
                exercise.
              </Typography>

              <Typography variant="body1" gutterBottom>
                You can use any events you wish, whether they are standard or custom events. Just
                change the event name in the script to match the event name and install the script in
                the desired location or action on the site. The script is always the same, just swap
                the event name.
              </Typography>

              <CopyScript
                description="Custom event script"
                alias="gtm-custom-event-script"
                rows={4}
                script={`<script>
  tlq('track', 'InsertYourEventNameHere');
</script>`}
                sx={{ mb: 2 }}
                descriptionSx={{ fontWeight: 600, mt: 0.5 }}
              />

              <Typography variant="body1" gutterBottom>
                Just remember to add the right trigger for each event and configure Tag Sequencing
                under Advanced Settings. The Tracklution main script tag you created in the first step
                has to be always fired before any event tags.
              </Typography>

              <Typography variant="body1" gutterBottom>
                List of frequently used standard events: <code>ViewContent</code>,{' '}
                <code>AddToCart</code>, <code>InitiateCheckout</code>, <code>Purchase</code>,{' '}
                <code>Lead</code>.{' '}
                <Link
                  target="_blank"
                  href="https://docs.tracklution.com/installation/gtm-installation"
                >
                  Full list of standard events
                </Link>
              </Typography>

              <Typography variant="body1">
                Hungry for more?{' '}
                <Link
                  target="_blank"
                  href="https://docs.tracklution.com/installation/gtm-installation"
                >
                  Read how to send eCommerce variables with your events
                </Link>
              </Typography>
            </InstallationStep>

            <InstallationFinalStep
              title={isDataRequiredToContinue ? 'Verify the installation and you are ready! 🌟' : 'You are ready! 🌟'}
              description={<>
                <Typography variant="body1" gutterBottom>
                  Congratulations, you did it!! Now your GTM setup is ready to rock.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  P.S. Do not forget to push your GTM changes live 😉
                </Typography>
              </>}
              trackingTag={trackingTag}
              isDataRequiredToContinue={isDataRequiredToContinue}
              noPadding={noPadding}
              onRefresh={onRefresh}
              eventCount={eventCount}
              webhookCount={webhookCount}
              onComplete={handleComplete}
            />
          </Stack>
        </Stack>
      </Container>
    )
  ;

  return <>{renderInstallation}</>;
}

InstallationGTM.propTypes = {
  trackingTag: PropTypes.object,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  noPadding: PropTypes.bool,
  onRefresh: PropTypes.func,
  eventCount: PropTypes.number,
  webhookCount: PropTypes.number,
  dnsStatus: PropTypes.number,
  isDataRequiredToContinue: PropTypes.bool,
};
