'use client';

import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import PrismicOnboardingShare from 'src/components/prismic/onboarding/prismic-onboarding-share';

// ----------------------------------------------------------------------

export default function InstallationHeader({ trackingTag, installationMethod, icon, title, description, onPrevious, noPadding }) {
  const renderHeader = (
    <Stack sx={{ pl: { xs: 0, md: noPadding ? 0 : 2.5 } }}>
      <Grid container spacing={2}>
        {!noPadding && (
          <Grid
            item
            xs={12}
            md={1.5}
            sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center' }}
          />
        )}
        <Grid
          item
          xs={12}
          md={1.5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {icon}
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack spacing={0.5}>
            {title}
            {description}
          </Stack>
          <Stack sx={{ mt: 2 }} direction="row" alignItems="center" spacing={1} flexWrap="wrap">
            <PrismicOnboardingShare trackingTag={trackingTag} installationMethod={installationMethod} buttonVariant="outlined" buttonSx={{ bgcolor: 'background.paper' }} />
            <Button
              variant="outlined"
              onClick={onPrevious}
              sx={{ textTransform: 'none', bgcolor: 'background.paper' }}
            >
              Change installation method
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );

  return <>{renderHeader}</>;
}

InstallationHeader.propTypes = {
  trackingTag: PropTypes.object,
  installationMethod: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  onPrevious: PropTypes.func,
  noPadding: PropTypes.bool,
};
