'use client';

import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { truncate } from 'src/utils/format-string';
import OnboardingStepTesting from 'src/components/onboarding/partials/onboarding-step-testing';
import { TextIconLabel } from 'src/components/landing';
import InstallationStep from './installation-step';

// ----------------------------------------------------------------------

export default function InstallationFinalStep({
                                                title,
                                                description,
                                                trackingTag,
                                                isDataRequiredToContinue,
                                                eventCount,
                                                webhookCount,
                                                noPadding,
                                                onRefresh,
                                                onComplete,
                                              }) {

  const [isStepComplete, setIsStepComplete] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [visibleCheckCount, setVisibleCheckCount] = useState(0);

  const handleTest = () => {
    setIsTesting(true);
  };

  useEffect(() => {
    let checkCount = 0;
    const testInterval = isTesting && setInterval(() => {
      onRefresh();
      if (eventCount > 0 || webhookCount > 0) {
        setIsStepComplete(true);
        setIsTesting(false);
      }
      checkCount += 1;
      if (checkCount > 100) {
        setIsTesting(false);
      }
      setVisibleCheckCount(checkCount);
    }, 3000);

    return () => {
      if (testInterval) {
        clearInterval(testInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTesting, eventCount, webhookCount]);

  return <InstallationStep
    stepTitle=""
    title={title ?? (isDataRequiredToContinue ? 'Verify the installation and you are ready! 🌟' : 'You are ready! 🌟')}
    help={[]}
    isLast
    noPadding={noPadding}
  >
    {description}
    {isDataRequiredToContinue && <Typography variant="body1" gutterBottom>
      Start listening for the first events from {truncate(trackingTag?.title || 'your site')}. After the first events have come through, go to
      Tracklution Dashboard to see your data and add Connectors to your favorite
      ad and analytics platforms!
    </Typography>}
    {!isDataRequiredToContinue && <>
      <Typography variant="body1" gutterBottom>
        Next, Go to Tracklution Dashboard to see your data and add Connectors to your favorite
        ad and analytics
        platforms!</Typography>
      <Typography variant="body1" gutterBottom>If you want, you can verify the installation by listening for the first
        events from {truncate(trackingTag?.title || 'your site')}.
      </Typography>
    </>}
    <Stack spacing={4}>
      <Box sx={{ mt: 1.5 }}>
        <OnboardingStepTesting
          startLabel="Start Listening for Events"
          testingLabel="Listening for Events..."
          completeLabel="Events Received Successfully"
          onTest={handleTest}
          isTesting={isTesting}
          isComplete={isStepComplete}
          checkCount={visibleCheckCount}
        />
      </Box>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="accent"
          size="large"
          onClick={onComplete}
          disabled={isDataRequiredToContinue && !isStepComplete}
        >
          Continue
        </Button>

        {isStepComplete && <TextIconLabel
          icon={
            <Iconify
              icon="mingcute:check-fill"
              sx={{ width: 20, height: 20, mr: 1, color: 'success.main' }}
            />
          }
          sx={{ mr: 2, typography: 'subtitle2' }}
          value="Completed!"
        />}
      </Stack>
    </Stack>
  </InstallationStep>;
}

InstallationFinalStep.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  trackingTag: PropTypes.object,
  noPadding: PropTypes.bool,
  onRefresh: PropTypes.func,
  eventCount: PropTypes.number,
  webhookCount: PropTypes.number,
  onComplete: PropTypes.number,
  isDataRequiredToContinue: PropTypes.bool,
};
