'use client';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
// routes
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Grid from '@mui/material/Unstable_Grid2';
import { axiosPost } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';


// ----------------------------------------------------------------------

export default function OnboardingSendInstructions({ trackingTag, installationMethod, onClose, sx }) {
  const { enqueueSnackbar } = useSnackbar();

  const ContactSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    email: '',
    message: '',
  };

  const methods = useForm({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const url = `/guest/tracking/${trackingTag?.hash}/send-instructions`;
  const onSubmit = handleSubmit(async (data) => {
    try {
      const { success, message } = await axiosPost(url, { ...data, installationMethod });
      if (success) {
        enqueueSnackbar(message, { variant: 'success', autoHideDuration: 10000 });
        onClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(typeof error === 'string' ? error : error.message, { variant: 'error', autoHideDuration: 10000 });
    }
  });

  const textFieldInputSx = {
    background: (theme) => theme.palette.background.paper,
  };

  return (<Paper
      elevation={8}
      sx={{
        mt: 1,
        mb: 4,
        p: 3,
        borderRadius: 1.5,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'hidden',
        maxWidth: 500,
        mx: 'auto',
        ...sx,
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <Typography variant="h3" sx={{ mt: 1, mb: 1.5 }}>
          Send Instructions to Co-worker
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We will send the co-worker an email with a link to these instructions. The recipient will have access only to the instructions and will not
          be able to access any data. If you want to give an access to the data, you can give the permissions on the dashboard after this installation
          process.
        </Typography>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid container spacing={{ xs: 1, md: 2 }}>
            <Grid xs={12} md={12}>
              <RHFTextField
                name="email"
                label="Co-worker's Email"
                required
                InputProps={{ sx: textFieldInputSx }}
              />
            </Grid>
            <Grid xs={12} md={12}>
              <RHFTextField
                name="message"
                label="Write an additional message... (optional)"
                multiline
                rows={3}
                InputProps={{ sx: textFieldInputSx }}
              />
            </Grid>
            <Grid xs={12} md={12}>
              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" sx={{ mt: 1.5 }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  color="inherit"
                  variant="contained"
                  loading={isSubmitting}
                  endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
                  sx={{ pl: 4, pr: 3, lineHeight: 1.15 }}
                >
                  Send Instructions
                </LoadingButton>
                <LoadingButton
                  size="small"
                  sx={{ minWidth: 0 }}
                  onClick={onClose}
                ><Iconify icon="uil:times" width={20} />
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </Stack>
    </Paper>
  );
}

OnboardingSendInstructions.propTypes = {
  trackingTag: PropTypes.object.isRequired,
  installationMethod: PropTypes.string,
  onClose: PropTypes.func,
  sx: PropTypes.object,
};
