import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import Iconify from '../../iconify';
// @mui
//

// ----------------------------------------------------------------------

export default function OnboardingReadMore({ title, children, color = 'accent', size = 'medium', sx, typographySx }) {
  const [isOpen, setIsOpen] = useState(false);

  return <Stack direction='column' spacing={1} sx={sx}>
    <Button
      color={color}
      size={size}
      onClick={() => setIsOpen((prev) => !prev)}
      startIcon={isOpen ? <Iconify icon='eva:arrow-ios-upward-fill' width={22} /> : <Iconify icon='eva:arrow-ios-downward-fill' width={22} />}
      sx={{ ml: -0.75, mr: 'auto' }}>
      <Typography variant="subtitle1" sx={{fontWeight: 700, ...typographySx}}>{title}</Typography>
    </Button>
    {isOpen && children}
  </Stack>;
}


OnboardingReadMore.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
  color: PropTypes.string,
  size: PropTypes.string,
  typographySx: PropTypes.object
};

