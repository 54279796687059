export function fDomain(str) {
  const domain = str ? String(str) : '';
  return domain
    .replaceAll('http:', '')
    .replaceAll('https:', '')
    .replaceAll('www.', '')
    .replaceAll('//', '');
}

export function getOnboardingUrl(onboardingLink) {
  if (!onboardingLink?.uid) {
    return null;
  }

  return `/start/${onboardingLink.uid}`;
}

export function fDashes(str) {
  return String(str).replace(/.{3}(?!$)/g, '$&-');
}

export function truncate(str, maxLength = 30) {
  if (str.length <= maxLength) {
    return str;
  }
  return `${str.slice(0, maxLength - 3)}...`;
}
