import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import Iconify from '../iconify';
//

// ----------------------------------------------------------------------

export default function ExternalBreadcrumbs({ items, size = 'default', sx }) {
  return (
    <Stack direction="row" sx={{ display: 'inline-flex', flexWrap: 'wrap', verticalAlign: 'text-bottom', mb: -0.75, ...sx }}>
      {items.map((breadcrumb, index) => (
        <ExternalBreadcrumb
          key={breadcrumb.title}
          title={breadcrumb.title}
          icon={breadcrumb.icon}
          size={size}
          isLast={index === items.length - 1}
        />
      ))}
    </Stack>
  );
}

ExternalBreadcrumbs.propTypes = {
  items: PropTypes.array,
  size: PropTypes.string,
  sx: PropTypes.object,
};

// ----------------------------------------------------------------------

function ExternalBreadcrumb({ title, icon, size = 'default', isLast = false }) {
  let textVariant = 'body2';
  let iconWidth = 16;

  if (size === 'large') {
    textVariant = 'body2';
    iconWidth = 16;
  }

  return (
    <Stack direction="row" sx={{ display: 'inline-flex', mb: 0.75 }}>
      {icon && (
        <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Iconify icon={icon} width={20} sx={{ mr: 0.5 }} />
        </Stack>
      )}
      <Typography
        variant={textVariant}
        sx={{
          px: 1,
          py: 0.5,
          borderRadius: 1,
          bgcolor: 'background.neutral',
          border: '1px dashed',
          borderColor: 'divider',
          // height: '1.45rem',
          lineHeight: 1,
        }}
      >
        {title}
      </Typography>
      {!isLast && (
        <Iconify icon="solar:alt-arrow-right-outline" width={iconWidth} sx={{ mx: 0.5 }} />
      )}
    </Stack>
  );
}

ExternalBreadcrumb.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  size: PropTypes.string,
  isLast: PropTypes.bool,
};
