/* eslint-disable react/no-unescaped-entities */

'use client';

import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { Box, Container, Typography, TextField, InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import Iconify from 'src/components/iconify';
import CopyScript from 'src/components/copy-script/copy-script';
import PropTypes from 'prop-types';
import { HOST_API } from 'src/config-global';
import ExternalBreadcrumbs from 'src/components/onboarding/external-breadcrumbs';
import { axiosPut } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import { InstallationSidebarCompliance, InstallationSidebarWantToKnowMore } from './installation-sidebar-content';
import InstallationFinalStep from './installation-final-step';
import InstallationStep from './installation-step';
import InstallationHeader from './installation-header';

// ----------------------------------------------------------------------

export function LoadingBox() {
  return (
    <Box
      sx={{
        mt: 1,
        mb: 3,
        p: 4,
        borderRadius: 2,
        bgcolor: 'background.dark',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Iconify icon="svg-spinners:8-dots-rotate" width={32} color="accent.main" />
    </Box>
  );
}

export default function InstallationStripe({
                                             trackingTag,
                                             onNext,
                                             onPrevious,
                                             noPadding,
                                             onRefresh,
                                             eventCount,
                                             webhookCount,
                                             dnsStatus,
                                             isDataRequiredToContinue,
                                           }) {
  const { enqueueSnackbar } = useSnackbar();

  const [thankYouPageUrl, setThankYouPageUrl] = useState(trackingTag?.thank_you_page_url || '');
  const [isThankYouPageInputDone, setIsThankYouPageInputDone] = useState(!!(trackingTag?.thank_you_page_url));

  const getStripeThankYouPageUrl = (inputUrl) => {
    try {
      const hasProtocol = inputUrl.startsWith('http://') || inputUrl.startsWith('https://');
      const formattedUrl = hasProtocol ? inputUrl : `https://${inputUrl}`;

      const url = new URL(formattedUrl);
      url.searchParams.set('stripe_session_id', '{CHECKOUT_SESSION_ID}');

      return url.toString().replace('%7B', '{').replace('%7D', '}');
    } catch (error) {
      return null;
    }
  };

  const handleGenerateUrl = () => {
    if (!(thankYouPageUrl?.length)) {
      enqueueSnackbar('Please enter a valid Thank you page URL.', { variant: 'error' });
      return;
    }
    setIsThankYouPageInputDone(true);
    handleUpdateTrackingContainer({ thank_you_page_url: thankYouPageUrl, event_source_system: 'stripe' });
  };

  const handleUpdateTrackingContainer = async (data) => {
    const url = `/network/tracking/${trackingTag.id ?? 'empty'}/onboarding`;

    try {
      const { success, data: responseData } = await axiosPut(url, data);

    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error on create.', { variant: 'error' });
    }
  };

  const handleComplete = () => {
    if (onNext) {
      onNext();
      return;
    }
    if (window.location.pathname === `/dashboard/tracking/${trackingTag?.id}/details/?ready=1`) {
      window.location.reload();
      return;
    }
    window.location.href = `/dashboard/tracking/${trackingTag?.id}/dashboard?ready=1`;
  };

  const trackingId = trackingTag?.key;
  const trackingDomain = trackingTag?.domain;
  const trackingTitle =
    String(trackingTag?.title).length > 25
      ? `${trackingTag.title.substring(0, 25)}...`
      : trackingTag?.title;
  const trackingFallbackDomain = String(trackingTag?.fallback_domain || HOST_API)
    .replace('https://', '')
    .replace('http://', '');

  const stripeThankYouPageUrl = getStripeThankYouPageUrl(thankYouPageUrl);

  const renderInstallation = (
    <Container maxWidth="xl" sx={{ ...(noPadding && { px: { xs: 0, md: 0 } }) }}>
      <Stack
        spacing={2}
        sx={{
          justifyContent: 'center',
          minHeight: { xs: noPadding ? 0 : '55vh', md: noPadding ? 0 : '70vh' },
          pt: { xs: 3.5, md: noPadding ? 4 : 12 },
          pb: 5,
        }}
      >
        <InstallationHeader
          trackingTag={trackingTag}
          installationMethod="stripe"
          icon={<Iconify icon="logos:stripe" width={96} />}
          title={
            <Typography
              variant="h2"
              sx={{
                lineHeight: 1.1,
                mb: 0.5,
              }}
            >
              Install Stripe Tracking to {trackingTitle}
            </Typography>
          }
          description={
            <Typography variant="body1">
              Follow these steps to connect{' '}
              <Box component="span" sx={{ fontWeight: 600 }}>
                {trackingTitle || 'your website'}
              </Box>{' '}
              and sales data from Stripe with Google Ads, Google Analytics 4, Facebook and many
              other marketing platforms in few minutes.
            </Typography>
          }
          onPrevious={onPrevious}
          noPadding={noPadding}
        />
        <Stack sx={{ px: 0, py: 3, pl: { xs: 0, md: noPadding ? 0 : 3 } }}>
          <InstallationStep
            stepTitle="Step 1"
            title="Insert tracking script to your website"
            help={[
              ...InstallationSidebarWantToKnowMore('/installation/stripe-integration'),
            ]}
            isFirst
            noPadding={noPadding}
          >
            <Typography variant="body1" gutterBottom>
              Add the tracking script to your website so it loads on every page, preferably in the{' '}
              <code>{'<head>'}</code>. You can install it with GTM, a WordPress plugin, or another
              method. Contact us via chat if you have any questions!
            </Typography>

            {!trackingTag ? (
              <LoadingBox />
            ) : (
              <CopyScript
                description="Main script"
                alias="gtm-main-script"
                rows={10}
                script={`<script>
  !function(f,b,e,v,n,t,s)
  {if(f.tlq)return;n=f.tlq=function(){n.callMethod?
    n.callMethod(arguments):n.queue.push(arguments)};
    if(!f._tlq)f._tlq=n;n.push=n;n.loaded=!0;n.version='1.0';n.src=v;
    n.queue=[];t=b.createElement(e);t.async=!0;n.pd = false;n.tools = null;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s);t.onerror=function(){
    v='https://${trackingFallbackDomain}/js/script-dynamic.js?version=${+Date.now()}';
    f._tlq.src=v;t=b.createElement(e);t.async=!0;t.src=v;
    s.parentNode.insertBefore(t, s)
  }}(window,document,'script',
    'https://${trackingDomain}/js/script-dynamic.js?version=${+Date.now()}');
  
  tlq('init', '${trackingId}');
  tlq('track', 'PageView');
</script>`}
                sx={{ mb: 3, mt: 0.5 }}
                descriptionSx={{ fontWeight: 600, mt: 0.5 }}
              />
            )}
          </InstallationStep>

          <InstallationStep
            stepTitle="Step 2"
            title="Update Stripe Thank you page URL"
            help={[
              <Stack>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  Do you need help?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  On the bottom right corner, you can chat with our support team.
                </Typography>
              </Stack>,
              ...InstallationSidebarCompliance({ mb: -40 }),
            ]}
            noPadding={noPadding}
          >

            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Enter your Thank you page URL here
            </Typography>
            <Typography variant="body2">
              This is the page on your website where customers will be redirected after a successful
              payment. Example: <code>www.yourshop.com/thank-you</code>
            </Typography>
            <TextField
              margin="dense"
              placeholder="e.g. www.yourshop.com/thank-you"
              value={thankYouPageUrl}
              onChange={(event) => {
                setIsThankYouPageInputDone(false);
                setThankYouPageUrl(event.target.value);
              }}
              fullWidth
              InputProps={{
                startAdornment:
                  <InputAdornment position="start" sx={{ mt: -0.25, ml: 0.5, mr: 1.5 }}>
                    <Iconify
                      icon="solar:global-outline"
                      sx={{ width: 22, height: 22, color: 'accent.main' }}
                    />
                  </InputAdornment>,
              }}
            />
            <Button
              variant="contained"
              color="accent"
              size="small"
              onClick={handleGenerateUrl}
              sx={{ mr: 'auto', mt: 1, textTransform: 'none' }}
            >
              Generate URL for Stripe
            </Button>

            {isThankYouPageInputDone && (
              <>
                <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
                  Go to Stripe, open{' '}
                  <ExternalBreadcrumbs
                    items={[
                      { title: 'Payment Links' },
                      { title: 'Edit payment link' },
                      { title: 'After payment' },
                    ]}
                    sx={{ display: 'inline', verticalAlign: 'middle' }}
                  />{' '}
                  and select "Don’t show confirmation page, Redirect customers to your website."
                </Typography>

                <CopyScript
                  description="Copy this to Stripe"
                  alias="thank-you-page-url-example"
                  rows={2}
                  script={stripeThankYouPageUrl}
                  sx={{ mb: 3, '& textarea': { pb: 1.5 }, mt: 2 }}
                  descriptionSx={{ fontWeight: 600, mt: 0.5 }}
                />

                <Typography variant="body1" gutterBottom>
                  <Box sx={{ fontWeight: 600 }} component="span">
                    Important!
                  </Box>{' '}
                  Double-check that your Thank You Page URL is correct. An invalid URL would direct your customers to the wrong page. Then copy the
                  above URL to Stripe.
                </Typography>
              </>
            )}
          </InstallationStep>

          <InstallationStep
            stepTitle="Step 3"
            title="Add Stripe Webhook"
            help={[]}
            noPadding={noPadding}
          >
            <Typography variant="body1" gutterBottom>
              Go to Stripe, open{' '}
              <ExternalBreadcrumbs
                items={[{ title: 'Developers' }, { title: 'Webhooks' }, { title: 'Add endpoint' }]}
                sx={{ display: 'inline', verticalAlign: 'middle' }}
              />
              .
            </Typography>
            <Typography variant="body1" gutterBottom>
              Make the following settings. Other settings can be left as default.
            </Typography>

            {!trackingTag ? (
              <LoadingBox />
            ) : (
              <CopyScript
                description="Endpoint URL"
                alias="webhook-endpoint-url"
                rows={2}
                script={`https://${trackingFallbackDomain}/collect/hook?k=${trackingId}&track=Purchase&source=stripe`}
                sx={{ mb: 1.5, '& textarea': { pb: 1.5 } }}
                descriptionSx={{ fontWeight: 600, mt: 0.5 }}
              />
            )}
            <Stack sx={{ my: 1, mb: 3 }}>
              <Stack
                direction="column"
                sx={{ display: 'flex', flexWrap: 'wrap', mb: { xs: 2, md: 0 } }}
                justifyContent="space-between"
              >
                <Stack sx={{ minWidth: 200, fontWeight: 600, width: { xs: '100%', md: 'auto' } }}>
                  Select events to listen to
                </Stack>
                <Stack>checkout.session.completed</Stack>
              </Stack>
            </Stack>

            <Typography variant="body1" gutterBottom>
              Save the webhook by clicking "Add endpoint" and you are ready!
            </Typography>
          </InstallationStep>

          <InstallationFinalStep
            title={isDataRequiredToContinue ? 'Verify the installation and you are ready! 🌟' : 'You are ready! 🌟'}
            description={<Typography variant="body1" gutterBottom>
              Congratulations, you did it!! Now your Stripe setup is ready to rock. 🥁🎸
            </Typography>}
            trackingTag={trackingTag}
            isDataRequiredToContinue={isDataRequiredToContinue}
            noPadding={noPadding}
            onRefresh={onRefresh}
            eventCount={eventCount}
            webhookCount={webhookCount}
            onComplete={handleComplete}
          />
        </Stack>
      </Stack>
    </Container>
  );

  return <>{renderInstallation}</>;
}

InstallationStripe.propTypes = {
  trackingTag: PropTypes.object,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  noPadding: PropTypes.bool,
  onRefresh: PropTypes.func,
  eventCount: PropTypes.number,
  webhookCount: PropTypes.number,
  dnsStatus: PropTypes.number,
  isDataRequiredToContinue: PropTypes.bool,
};
