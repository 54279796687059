'use client';

import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import Link from '../../../components/link/link';
import { Image } from '../../../components/landing';

// ----------------------------------------------------------------------

export const InstallationSidebarWantToKnowMore = (documentationPath) => [
  <Stack>
    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
      Want to know more?
    </Typography>
    <Typography variant="body2" color="text.secondary">
      Read our{' '}
      <Link
        href={`https://docs.tracklution.com${documentationPath}`}
        target="_blank"
        sx={{ fontWeight: 500 }}
      >
        documentation
      </Link>{' '}
      for more information.
    </Typography>
  </Stack>,
  <Stack spacing={3}>
    <Stack>
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
        Why choose Tracklution?
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Tracklution is the easiest way to improve your ad performance. We are trusted by 1,000+ companies worldwide, including all the major ad
        platforms.
      </Typography>
    </Stack>
    <Stack spacing={2}>
      <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
        <Image
          src="/assets/images/badges/meta-partner-badge.png"
          sx={{ width: 140 }}
        />
        <Stack sx={{
          bgcolor: 'white',
          width: 135,
          alignItems: 'center',
          justifyContent: 'center',
          px: 1,
          py: 1.5,
          border: '1px solid #566168',
          borderRadius: 0.75,
        }}>
          <Iconify icon="logos:google-ads" width={34} />
          <Box sx={{ lineHeight: 1, fontSize: '0.8rem', mt: 1, fontWeight: 500 }}>Unlimited API</Box>
        </Stack>
      </Stack>
      <Image
        src="/assets/images/badges/rating-badge.png"
        sx={{ width: 200, mt: 1 }}
      />
    </Stack>
  </Stack>,
];


export const InstallationSidebarCompliance = (sx) => [
  <Stack spacing={3} sx={sx}>
    <Stack>
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
        Are you compliant?
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Yes! We are fully compliant with all major privacy regulations, including GDPR and SOC2.
      </Typography>
    </Stack>
    <Stack spacing={2}>
      <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
        <Image
          src="/assets/images/badges/soc2-badge.png"
          sx={{ width: 140 }}
        />
      </Stack>
    </Stack>
  </Stack>,
];
