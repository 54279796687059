'use client';

import Stack from '@mui/material/Stack';
import { Grid, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import { useResponsive } from '../../../hooks/use-responsive';

// ----------------------------------------------------------------------

export default function InstallationStep({
                                           stepTitle,
                                           title,
                                           children,
                                           help,
                                           noPadding,
                                           isFirst = false,
                                           isLast = false,
                                         }) {
  const isDesktop = useResponsive('up', 'md');

  const contentPy = { xs: 4, md: 6 };

  const renderInstallationStep = (
    <Stack>
      <Grid container spacing={3} justifyContent={{ md: noPadding ? 'start' : 'center' }}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={3} justifyContent={{ md: noPadding ? 'start' : 'center' }}>
            {isDesktop && !noPadding && <Grid item xs={12} md={1} />}
            <Grid item xs={12} md={noPadding ? 9 : 7}>
              <Paper
                elevation={0}
                sx={{
                  // my: 2,
                  border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.5)}`,
                  borderBottom: (theme) => !isLast && `none`,
                  borderRadius: 0,
                  borderTopLeftRadius: (theme) => isFirst && theme.spacing(3),
                  borderTopRightRadius: (theme) => isFirst && theme.spacing(3),
                  borderBottomLeftRadius: (theme) => isLast && theme.spacing(3),
                  borderBottomRightRadius: (theme) => isLast && theme.spacing(3),
                }}
              >
                <Stack direction="row">
                  {isDesktop && (
                    <Stack sx={{ px: 2, py: contentPy, pr: 4 }}>
                      <Typography
                        variant="h5"
                        align="right"
                        sx={{
                          lineHeight: 1.2,
                          // color: 'accent.main',
                          minWidth: 100,
                          mt: 0.25,
                        }}
                      >
                        {stepTitle}
                      </Typography>
                    </Stack>
                  )}
                  <Stack
                    sx={{ py: contentPy, px: 4, pr: { xs: 4, md: 8 }, pb: 0, width: '100%' }}
                    spacing={2}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        lineHeight: 1.2,
                        fontSize: { xs: '1.35rem', md: '1.5rem' },
                      }}
                    >
                      {title}
                    </Typography>
                    <Stack>{children}</Stack>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
            {isDesktop && (
              <Grid item xs={12} md={3}>
                {help?.map((content, index) => (
                  <Stack direction="row" sx={{ pr: 2, py: 1.5, zoom: 0.9, maxWidth: 300, width: '100%' }} key={index}>
                    <Stack sx={{ px: 1, py: 0.25 }}>
                      <Iconify icon="solar:question-circle-outline" width={24} />
                    </Stack>
                    <Stack sx={{ width: '100%' }}>{content}</Stack>
                  </Stack>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );

  return <>{renderInstallationStep}</>;
}

InstallationStep.propTypes = {
  stepTitle: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  help: PropTypes.array,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  noPadding: PropTypes.bool,
};
