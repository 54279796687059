import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Iconify from '../../iconify';
import { TextIconLabel } from '../../landing';
import { IS_CLIENT_SERVER } from '../../../config-global';
import { useSignupContext } from '../../signup-popup/context/signup-context';
import { useResponsive } from '../../../hooks/use-responsive';
// @mui
//

// ----------------------------------------------------------------------

export default function PrismicOnboardingNavigation({
                                                      onNext,
                                                      onPrevious,
                                                      isOnboardingDone = false,
                                                      isCurrentComplete = true,
                                                      isSkippable = true,
                                                      nextLabel = 'Next',
                                                      previousLabel = 'Previous',
                                                      contactButtonLabel = null,
                                                      skipLabel = 'Complete later',
                                                      buttonSize = 'large',
                                                      isCurrentCompleteVisible = false,
                                                      isLoading = false,
                                                    }) {
  const signup = useSignupContext();
  const isMobile = useResponsive('down', 'sm');

  if (isOnboardingDone) {
    return <Stack direction="row" spacing={1}>
      <Box flexGrow={1} />
      <Button
        color="accent"
        variant="contained"
        size={buttonSize}
        endIcon={<Iconify icon="carbon:chevron-right" />}
        onClick={onNext}
        sx={{ px: 3, py: 1 }}
      >
        {nextLabel}
      </Button>
    </Stack>;
  }

  const realButtonSize = buttonSize === 'xlarge' ? 'large' : buttonSize;
  const buttonSx = buttonSize === 'xlarge' ? { px: 3.5, py: 3.25, fontSize: '1.05rem', lineHeight: 1.15 } : { px: 3, py: 1, lineHeight: 1.15 };

  const showContactButton = contactButtonLabel && !IS_CLIENT_SERVER && signup.onboardingSource?.type !== 'agency';

  return <Stack direction="row" spacing={1} flexWrap={onPrevious ? 'wrap-reverse' : 'nowrap'} justifyContent="space-between">
    {showContactButton && (
      <Button
        size="large"
        onClick={() => signup.onToggle('contact')}
        sx={{ px: 2, ml: -2, py: 3.25, textAlign: 'left', textDecoration: 'underline', lineHeight: 1.25 }}
      >
        {contactButtonLabel}
      </Button>
    )}
    <Stack direction="row" spacing={1} sx={{ flexGrow: 1, justifyContent: { xs: 'space-between', sm: 'end' } }}>
      {onPrevious && <Button
        // variant="contained"
        size={realButtonSize}
        // endIcon={<Iconify icon="carbon:chevron-right" />}
        onClick={onPrevious}
        sx={{ ...buttonSx, fontSize: '95%', ml: -3.5, ...(!onNext && { mr: 'auto' }) }}
      >
        {previousLabel}
      </Button>}
      {!isCurrentComplete && isSkippable && <Button
        onClick={onNext}
        sx={{ px: 3, py: 1, opacity: 0.8 }}
      >
        {skipLabel}
      </Button>}
      {!showContactButton && isCurrentComplete && isCurrentCompleteVisible && <TextIconLabel
        icon={
          <Iconify
            icon="mingcute:check-fill"
            sx={{ width: 20, height: 20, mr: 1, color: 'success.main' }}
          />
        }
        sx={{ mr: 2, typography: 'subtitle2' }}
        value="Completed!"
      />}
      {onNext && <Button
        color="accent"
        variant="contained"
        size={realButtonSize}
        endIcon={!isMobile && (isLoading ? <Iconify icon="svg-spinners:8-dots-rotate" width={24} /> :
          <Iconify icon="solar:double-alt-arrow-right-bold" width={24} />)}
        onClick={onNext}
        sx={{ ...buttonSx, ml: !onPrevious && 'auto', ...(isLoading && { pointerEvents: 'none' }) }}
        disabled={!isCurrentComplete}
      >
        {nextLabel}
      </Button>}
    </Stack>
  </Stack>;
}


PrismicOnboardingNavigation.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  isOnboardingDone: PropTypes.bool,
  isCurrentComplete: PropTypes.bool,
  isSkippable: PropTypes.bool,
  nextLabel: PropTypes.string,
  previousLabel: PropTypes.string,
  contactButtonLabel: PropTypes.string,
  skipLabel: PropTypes.string,
  buttonSize: PropTypes.string,
  isCurrentCompleteVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
};

