import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Iconify from '../../iconify';
import { copyToClipboard } from '../../../utils/general';
import { useSnackbar } from '../../snackbar';
import OnboardingSendInstructions from '../../onboarding/partials/onboarding-send-instructions';
// @mui
//

// ----------------------------------------------------------------------

export default function PrismicOnboardingShare({
                                                 trackingTag = null,
                                                 installationMethod = null,
                                                 buttonSize = 'medium',
                                                 buttonVariant = null,
                                                 buttonSx = null,
                                               }) {
  const { enqueueSnackbar } = useSnackbar();
  const [showSendInstructions, setShowSendInstructions] = useState(false);

  const copyLinkToOnboarding = () => {
    const link = `${window.location.host}/onboarding/${trackingTag?.hash}/${installationMethod ? `?method=${installationMethod}` : ''}`;

    copyToClipboard(link);
    enqueueSnackbar('Link Copied to Clipboard.', { autoHideDuration: 1500 });
  };

  return <>
    <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
      <Button
        startIcon={!trackingTag ? <Iconify icon="svg-spinners:8-dots-rotate" width={18} /> :
          <Iconify icon="eva:copy-fill" width={buttonSize === 'small' ? 20 : 20} sx={{ color: 'accent.main' }} />}
        onClick={copyLinkToOnboarding}
        sx={{ whiteSpace: 'nowrap', textTransform: 'none', ...buttonSx, ...(!trackingTag && { pointerEvents: 'none' }) }}
        size={buttonSize}
        variant={buttonVariant}
      >
        Copy Link to Instructions
      </Button>
      <Button
        startIcon={!trackingTag ? <Iconify icon="svg-spinners:8-dots-rotate" width={18} /> :
          <Iconify color="accent" icon="eva:share-fill" width={buttonSize === 'small' ? 20 : 20} sx={{ color: 'accent.main' }} />}
        onClick={() => setShowSendInstructions((prev) => !prev)}
        sx={{ whiteSpace: 'nowrap', textTransform: 'none', ...buttonSx, ...(!trackingTag && { pointerEvents: 'none' }) }}
        size={buttonSize}
        variant={buttonVariant}
      >
        Send to Co-worker
      </Button>
    </Stack>
    {showSendInstructions && (
      <Dialog open={showSendInstructions} onClose={() => setShowSendInstructions(false)}
              slotProps={{
                backdrop: { invisible: false, sx: { bgcolor: 'rgba(22, 28, 36, 0.5)' } },
              }}>
        <OnboardingSendInstructions
          trackingTag={trackingTag}
          installationMethod={installationMethod}
          onClose={() => setShowSendInstructions(false)}
          sx={{ mt: 0, mb: 0 }}
        />
      </Dialog>
    )}
  </>;
}


PrismicOnboardingShare.propTypes = {
  trackingTag: PropTypes.object,
  installationMethod: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonVariant: PropTypes.string,
  buttonSx: PropTypes.object,
};

