'use client';

import Stack from '@mui/material/Stack';
import { Box, Container, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import ExternalBreadcrumbs from 'src/components/onboarding/external-breadcrumbs';
import CopyScript from 'src/components/copy-script/copy-script';
import PropTypes from 'prop-types';
import { HOST_API } from 'src/config-global';
import InstallationStep from './installation-step';
import InstallationHeader from './installation-header';
import { truncate } from '../../../utils/format-string';
import { InstallationSidebarCompliance, InstallationSidebarWantToKnowMore } from './installation-sidebar-content';
import InstallationFinalStep from './installation-final-step';

// ----------------------------------------------------------------------

export default function InstallationShopify({
                                              trackingTag,
                                              onNext,
                                              onPrevious,
                                              noPadding,
                                              onRefresh,
                                              eventCount,
                                              webhookCount,
                                              dnsStatus,
                                              isDataRequiredToContinue,
                                            }) {
  const handleComplete = () => {
    if (onNext) {
      onNext();
      return;
    }
    if (window.location.pathname === `/dashboard/tracking/${trackingTag?.id}/details/?ready=1`) {
      window.location.reload();
      return;
    }
    window.location.href = `/dashboard/tracking/${trackingTag?.id}/dashboard?ready=1`;
  };

  const trackingId = trackingTag?.key;
  const trackingDomain = trackingTag?.domain;
  const trackingFallbackDomain = String(trackingTag?.fallback_domain || HOST_API)
    .replace('https://', '')
    .replace('http://', '');


  const renderInstallation = (
    <Container maxWidth="xl" sx={{ ...(noPadding && { px: { xs: 0, md: 0 } }) }}>
      <Stack
        spacing={2}
        sx={{
          justifyContent: 'center',
          minHeight: { xs: noPadding ? 0 : '55vh', md: noPadding ? 0 : '70vh' },
          pt: { xs: 3.5, md: noPadding ? 4 : 12 },
          pb: 5,
        }}
      >
        <InstallationHeader
          trackingTag={trackingTag}
          installationMethod="shopify"
          icon={<Iconify icon="logos:shopify" width={96} />}
          title={
            <Stack>
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.1,
                  mb: 0.5,
                  fontSize: { xs: '1.8rem', md: '3rem' },
                }}
              >
                Shopify Installation
              </Typography>
            </Stack>
          }
          description={
            <Stack sx={{ maxWidth: 600 }}>
              <Typography variant="body1">
                Follow these steps to install Tracklution to <Box component="span"
                                                                  sx={{ fontWeight: 600 }}>{truncate(trackingTag?.title || 'Your website')}</Box> and
                experience{' '}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  +36.7 % improved conversions{' '}
                </Box>
                on average.
              </Typography>
            </Stack>
          }
          onPrevious={onPrevious}
          noPadding={noPadding}
        />
        <Stack sx={{ px: 0, py: 3, pl: { xs: 0, md: noPadding ? 0 : 3 } }}>
          <InstallationStep
            stepTitle="Step 1"
            title="Add Customer Events"
            help={[
              <Stack>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  What are the benefits?
                </Typography>
                <Typography variant="body2">
                  Our GTM users experience on average{' '}
                  <Box component="span" sx={{ color: 'accent.main', fontWeight: 500 }}>
                    +36.7 % improved conversions{' '}
                  </Box>.
                </Typography>
              </Stack>,
              ...InstallationSidebarWantToKnowMore('/installation/shopify-app'),
            ]}
            isFirst
            noPadding={noPadding}
          >
            <Typography variant="body1" gutterBottom>
              Sign in to your Shopify, go to
            </Typography>

            <ExternalBreadcrumbs
              items={[
                { title: 'Settings' },
                { title: 'Customer events' },
                { title: 'Add custom pixel' },
              ]}
              sx={{ mb: 2 }}
            />
            <Typography variant="body1" gutterBottom>
              Set Pixel name as Tracklution App and Add pixel.
            </Typography>
            <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
              Customer Privacy
            </Typography>

            <Typography variant="body1" gutterBottom>
              Do the following settings.
            </Typography>
            <Stack sx={{ my: 1 }}>
              <Stack
                direction="row"
                sx={{ display: 'flex', flexWrap: 'wrap', mb: { xs: 2, md: 0 } }}
              >
                <Stack sx={{ minWidth: 100, fontWeight: 600, width: { xs: '100%', md: 'auto' } }}>
                  Permission
                </Stack>
                <Stack>Not required</Stack>
              </Stack>
              <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Stack sx={{ minWidth: 100, fontWeight: 600, width: { xs: '100%', md: 'auto' } }}>
                  Data sale
                </Stack>
                <Stack>Data collected does not qualify as data sale</Stack>
              </Stack>
              <Typography variant="caption" sx={{ mt: 2 }}>
                (Tracklution reads consents automatically)
              </Typography>
            </Stack>
            <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
              Code
            </Typography>
            <CopyScript
              description="Copy-paste the following code to the Code field."
              alias="shopify-customer-events"
              rows={5}
              script={`!function(f,b,e,v,n,t,s)
{if(f.tlq)return;n=f.tlq=function(){n.callMethod?
  n.callMethod(arguments):n.queue.push(arguments)};
  if(!f._tlq)f._tlq=n;n.push=n;n.loaded=!0;n.version='1.0';n.src=v;
  n.queue=[];t=b.createElement(e);t.async=!0;n.pd = false;n.tools = null;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s);t.onerror=function(){
  v='https://${trackingFallbackDomain}/js/script-dynamic.js?version=${+Date.now()}';
  f._tlq.src=v;t=b.createElement(e);t.async=!0;t.src=v;
  s.parentNode.insertBefore(t, s)
}}(window,document,'script',
  'https://${trackingDomain}/js/script-dynamic.js?version=${+Date.now()}');


analytics.subscribe('page_viewed', (event) => {
  tlq('init', '${trackingId}', { url: event.context?.document?.location?.href, api: true, inject: true });

  tlq('track', 'PageView', {
    target: '${trackingId}',
    url: event.context?.document?.location?.href,
  });
});

analytics.subscribe('product_viewed', async (event) => {
  const productVariant = event.data?.productVariant;

  const item = {
    item_id: productVariant?.sku,
    item_name: productVariant?.product?.title,
    item_variant: productVariant?.title,
    item_brand: productVariant?.product?.vendor,
    price: productVariant?.price?.amount,
    currency: productVariant?.price?.currencyCode,
  };

  tlq('track', 'ViewContent', {
    target: '${trackingId}',
    url: event.context?.document?.location?.href,
    items: [item],
  });
});


analytics.subscribe('product_added_to_cart', (event) => {
  const cartLine = event.data?.cartLine;
  const productVariant = cartLine?.merchandise;

  const item = {
    item_id: productVariant?.sku,
    item_name: productVariant?.product?.title,
    item_variant: productVariant?.title,
    item_brand: productVariant?.product?.vendor,
    price: productVariant?.price?.amount,
    currency: productVariant?.price?.currencyCode,
  };

  tlq('track', 'AddToCart', {
    target: '${trackingId}',
    url: event.context?.document?.location?.href,
    items: [item],
  });
});

analytics.subscribe('payment_info_submitted', (event) => {
  tlq('track', 'AddPaymentInfo', {
    target: '${trackingId}',
    url: event.context?.document?.location?.href,
  });

  const billingAddress = event.data?.checkout?.billingAddress;

  tlq('set', 'ContactInfo', {
    target: '${trackingId}',
    email: event.data?.checkout?.email,
    phoneNumber: event.data?.checkout?.phone || billingAddress?.phone,
    firstName: billingAddress?.firstName,
    lastName: billingAddress?.lastName,
    address: billingAddress?.address1,
    postCode: billingAddress?.zip,
    city: billingAddress?.city,
    country: billingAddress?.country,
    isFirstOrder: event.data?.checkout?.order?.customer?.isFirstOrder,
  });
});

analytics.subscribe('checkout_started', (event) => {
  let lineItems = [];

  if (event.data?.checkout?.lineItems) {
    for (const checkoutLineItem of event.data.checkout.lineItems) {
      lineItems.push({
        item_id: checkoutLineItem?.variant?.sku,
        item_name: checkoutLineItem?.title,
        item_variant: checkoutLineItem?.variant?.title,
        item_brand: checkoutLineItem?.variant?.product?.vendor,
        price: checkoutLineItem?.variant?.price?.amount,
        quantity: checkoutLineItem?.quantity,
      });
    }
  }

  tlq('track', 'InitiateCheckout', {
    target: '${trackingId}',
    url: event.context?.document?.location?.href,
    items: lineItems,
  });
});

analytics.subscribe('checkout_completed', (event) => {
  let lineItems = [];

  if (event.data?.checkout?.lineItems) {
    for (const checkoutLineItem of event.data.checkout.lineItems) {
      lineItems.push({
        item_id: checkoutLineItem?.variant?.sku,
        item_name: checkoutLineItem?.title,
        item_variant: checkoutLineItem?.variant?.title,
        item_brand: checkoutLineItem?.variant?.product?.vendor,
        price: checkoutLineItem?.variant?.price?.amount,
        quantity: checkoutLineItem?.quantity,
      });
    }
  }

  tlq('track', 'Purchase', {
    target: '${trackingId}',
    url: event.context?.document?.location?.href,
    currency: event.data?.checkout?.currencyCode,
    value: event.data?.checkout?.totalPrice?.amount,
    items: lineItems,
  });

  const billingAddress = event.data?.checkout?.billingAddress;

  tlq('set', 'ContactInfo', {
    target: '${trackingId}',
    email: event.data?.checkout?.email,
    phoneNumber: event.data?.checkout?.phone || billingAddress?.phone,
    externalId: event?.data?.checkout?.order?.id,
    firstName: billingAddress?.firstName,
    lastName: billingAddress?.lastName,
    address: billingAddress?.address1,
    postCode: billingAddress?.zip,
    city: billingAddress?.city,
    country: billingAddress?.country,
    isFirstOrder: event.data?.checkout?.order?.customer?.isFirstOrder,
  });
});
`}
            />

            <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
              Finally, click <ExternalBreadcrumbs items={[{ title: 'Save' }]} /> from the top right
              corner.
            </Typography>

            <Typography variant="body1">
              And then <ExternalBreadcrumbs items={[{ title: 'Connect' }]} /> from the bottom right
              corner.
            </Typography>
          </InstallationStep>

          <InstallationStep
            stepTitle="Step 2"
            title="Add Webhook"
            help={[
              <Stack>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  Do you need help?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  On the bottom right corner, you can chat with our support team.
                </Typography>
              </Stack>,
              ...InstallationSidebarCompliance({ mb: -40 }),
            ]}
            noPadding={noPadding}
          >
            <Typography variant="body1" gutterBottom>
              In Shopify, go to
            </Typography>
            <ExternalBreadcrumbs
              items={[
                { title: 'Settings' },
                { title: 'Notifications' },
                { title: 'Webhooks' },
                { title: 'Create webhook' },
              ]}
            />

            <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
              Do the following settings.
            </Typography>
            <Stack sx={{ my: 1 }}>
              <Stack
                direction="row"
                sx={{ display: 'flex', flexWrap: 'wrap', mb: { xs: 2, md: 0 } }}
              >
                <Stack sx={{ minWidth: 200, fontWeight: 600, width: { xs: '100%', md: 'auto' } }}>
                  Event
                </Stack>
                <Stack>Order creation</Stack>
              </Stack>
              <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Stack sx={{ minWidth: 200, fontWeight: 600, width: { xs: '100%', md: 'auto' } }}>
                  Format
                </Stack>
                <Stack>JSON</Stack>
              </Stack>
              <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Stack sx={{ minWidth: 200, fontWeight: 600, width: { xs: '100%', md: 'auto' } }}>
                  Webhook API version
                </Stack>
                <Stack>2024-04</Stack>
              </Stack>
              <Stack>
                <CopyScript
                  alias="shopify-webhook-url"
                  description="URL"
                  descriptionSx={{ fontWeight: 600 }}
                  rows={2}
                  script={`https://${trackingFallbackDomain}/collect/hook?k=${trackingId}&track=Purchase&source=shopify`}
                />
              </Stack>
            </Stack>
          </InstallationStep>

          <InstallationFinalStep
            trackingTag={trackingTag}
            isDataRequiredToContinue={isDataRequiredToContinue}
            noPadding={noPadding}
            onRefresh={onRefresh}
            eventCount={eventCount}
            webhookCount={webhookCount}
            onComplete={handleComplete}
          />
        </Stack>
      </Stack>
    </Container>
  );

  return <>{renderInstallation}</>;
}

InstallationShopify.propTypes = {
  trackingTag: PropTypes.object,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  noPadding: PropTypes.bool,
  onRefresh: PropTypes.func,
  eventCount: PropTypes.number,
  webhookCount: PropTypes.number,
  dnsStatus: PropTypes.number,
  isDataRequiredToContinue: PropTypes.bool,
};
