import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Stack, Typography } from '@mui/material';
import Iconify from '../../iconify';
// @mui
//

// ----------------------------------------------------------------------

export default function OnboardingStepTesting({ startLabel, testingLabel, completeLabel, onTest, isComplete, isTesting, message, checkCount }) {

  const renderMessage = message && <Typography variant='body2' color='text.secondary' sx={{minHeight: 22}}>{message}</Typography>;

  if (isTesting) {
    return <Stack direction='column' spacing={1}>
      <Button
        color='inherit'
        variant='outlined'
        sx={{ px: 2, marginRight: 'auto' }}
        startIcon={<Iconify
          icon='svg-spinners:8-dots-rotate'
          sx={{ width: 16, height: 16, mr: 0.5, color: 'accent.main', textTransform: 'none' }}
        />}>
        {testingLabel}
      </Button>
      {renderMessage}
      {checkCount >= 10 && <Typography variant='caption' color='text.secondary' sx={{mt: 0}}>Verification #{checkCount} was unsuccessful. You got this!</Typography>}
    </Stack>;
  }

  if (isComplete) {
    return <Stack direction='column' spacing={1}>
      <Button
        color='inherit'
        variant='outlined'
        sx={{ px: 2, marginRight: 'auto' }}
        onClick={onTest}
        startIcon={<Iconify
          icon='mingcute:check-fill'
          sx={{ width: 20, height: 20, color: 'success.main', textTransform: 'none' }}

        />}>
        {completeLabel}
      </Button>
      {renderMessage}
    </Stack>;
  }

  return <Stack direction='column' spacing={1}>
    <Button
      color='accent'
      variant='outlined'
      sx={{ px: 2, marginRight: 'auto', textTransform: 'none' }}
      onClick={onTest}
    >
      {startLabel}
    </Button>
    {renderMessage}
  </Stack>;
}


OnboardingStepTesting.propTypes = {
  onTest: PropTypes.func,
  isComplete: PropTypes.bool,
  isTesting: PropTypes.bool,
  startLabel: PropTypes.string,
  testingLabel: PropTypes.string,
  completeLabel: PropTypes.string,
  message: PropTypes.string,
  checkCount: PropTypes.number,
};

