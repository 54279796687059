'use client';

import Stack from '@mui/material/Stack';
import { Box, Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Iconify from 'src/components/iconify';
import Link from 'src/components/link/link';
import ExternalBreadcrumbs from 'src/components/onboarding/external-breadcrumbs';
import PropTypes from 'prop-types';
import { IS_CLIENT_SERVER, HOST_API } from 'src/config-global';
import InstallationStep from './installation-step';
import InstallationHeader from './installation-header';
import { InstallationSidebarCompliance, InstallationSidebarWantToKnowMore } from './installation-sidebar-content';
import { truncate } from '../../../utils/format-string';
import InstallationFinalStep from './installation-final-step';

// ----------------------------------------------------------------------

export default function InstallationWooCommerce({
                                                  trackingTag,
                                                  onNext,
                                                  onPrevious,
                                                  noPadding,
                                                  onRefresh,
                                                  eventCount,
                                                  webhookCount,
                                                  dnsStatus,
                                                  isDataRequiredToContinue,
                                                }) {
  const handleComplete = () => {
    if (onNext) {
      onNext();
      return;
    }
    if (window.location.pathname === `/dashboard/tracking/${trackingTag?.id}/details/?ready=1`) {
      window.location.reload();
      return;
    }
    window.location.href = `/dashboard/tracking/${trackingTag?.id}/dashboard?ready=1`;
  };

  const renderInstallation = (
    <Container maxWidth="xl" sx={{ ...(noPadding && { px: { xs: 0, md: 0 } }) }}>
      <Stack
        spacing={{ xs: 4, md: 2 }}
        sx={{
          justifyContent: 'center',
          minHeight: { xs: noPadding ? 0 : '55vh', md: noPadding ? 0 : '70vh' },
          pt: { xs: 3.5, md: noPadding ? 4 : 12 },
          pb: 5,
        }}
      >
        <InstallationHeader
          trackingTag={trackingTag}
          installationMethod="woocommerce"
          icon={<Iconify icon="logos:woocommerce-icon" width={96} />}
          title={
            <Stack>
              <Typography
                variant="h2"
                sx={{
                  lineHeight: 1.1,
                  mb: 0.5,
                  fontSize: { xs: '1.8rem', md: '3rem' },
                }}
              >
                WooCommerce Installation
              </Typography>
            </Stack>
          }
          description={
            <Stack sx={{ maxWidth: 600 }}>
              <Typography variant="body1">
                Follow these steps to install Tracklution to <Box component="span"
                                                                  sx={{ fontWeight: 600 }}>{truncate(trackingTag?.title || 'Your website', 30)}</Box> and
                experience{' '}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  +35.1 % improved conversions{' '}
                </Box>
                on average.
              </Typography>
            </Stack>
          }
          onPrevious={onPrevious}
          noPadding={noPadding}
        />
        <Stack sx={{ px: 0, py: { xs: 0, md: 3 }, pl: { xs: 0, md: noPadding ? 0 : 3 } }}>
          <InstallationStep
            stepTitle="Step 1"
            title="Install Tracklution Plugin"
            help={[
              ...InstallationSidebarWantToKnowMore('/installation/woo-plugin'),
            ]}
            isFirst
            noPadding={noPadding}
          >
            <Typography variant="body1">
              Download the Official Tracklution WooCommerce plugin here.
            </Typography>
            <Link
              href="/assets/plugins/tracklution-for-woocommerce-10-02-2025.zip"
              target="_blank"
              sx={{ mt: 3, mb: 1.5, mr: 'auto' }}
            >
              <Button variant="contained" color="accent">
                Download Tracklution Plugin ZIP
              </Button>
            </Link>
            {false && (
              <Typography variant="caption">
                Note: Your browser might alert you that the file is not from trusted source. You can
                ignore this warning.
              </Typography>
            )}
            <Typography variant="h5" sx={{ mt: 4, fontWeight: 700 }} gutterBottom>
              Upload Plugin to WordPress
            </Typography>
            <Typography variant="body1" gutterBottom>
              Log into your WordPress. Go to{' '}
            </Typography>
            <ExternalBreadcrumbs
              items={[
                { title: 'Plugins' },
                { title: 'Add New Plugin' },
                { title: 'Upload Plugin' },
              ]}
              sx={{ mb: 2 }}
            />
            <Typography variant="body1">
              After this find Tracklution in the Plugin list and click{' '}
              <ExternalBreadcrumbs items={[{ title: 'Activate' }]} sx={{ mx: 0.5 }} />.
            </Typography>
          </InstallationStep>

          <InstallationStep
            stepTitle="Step 2"
            title="Tracklution Settings"
            help={[
              <Stack>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  Do you need help?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  On the bottom right corner, you can chat with our support team.
                </Typography>
              </Stack>,
              ...InstallationSidebarCompliance({ mb: -40 }),
            ]}
            noPadding={noPadding}
          >
            <Typography variant="body1" gutterBottom>
              In WordPress, go to
              <ExternalBreadcrumbs
                items={[{ title: 'Settings' }, { title: 'Tracklution' }]}
                sx={{ mx: 1 }}
              />
              and save these settings.
            </Typography>
            <Stack sx={{ my: 1 }}>
              <Stack
                direction="row"
                sx={{ display: 'flex', flexWrap: 'wrap', mb: { xs: 2, md: 1 }, alignItems: 'center' }}
              >
                <Stack sx={{ minWidth: 200, fontWeight: 600, width: { xs: '100%', md: 'auto' }, pr: 2 }}>
                  Tracklution ID
                </Stack>
                <Stack>{trackingTag?.key}</Stack>
              </Stack>
              <Stack
                direction="row"
                sx={{ display: 'flex', flexWrap: 'wrap', mb: { xs: 2, md: 1 }, alignItems: 'center' }}
              >
                <Stack sx={{ minWidth: 200, fontWeight: 600, width: { xs: '100%', md: 'auto' }, pr: 2 }}>
                  Tracklution Domain
                </Stack>
                <Stack>{trackingTag?.domain}</Stack>
              </Stack>
              {IS_CLIENT_SERVER && (
                <Stack
                  direction="row"
                  sx={{ display: 'flex', flexWrap: 'wrap', mb: { xs: 2, md: 1 }, alignItems: 'center' }}
                >
                  <Stack sx={{ minWidth: 200, fontWeight: 600, width: { xs: '100%', md: 'auto' }, pr: 2 }}>
                    Tracklution Fallback Domain
                  </Stack>
                  <Stack>{HOST_API.replace(/^https?:\/\//, '')}</Stack>
                </Stack>
              )}
            </Stack>
          </InstallationStep>

          <InstallationFinalStep
            trackingTag={trackingTag}
            isDataRequiredToContinue={isDataRequiredToContinue}
            noPadding={noPadding}
            onRefresh={onRefresh}
            eventCount={eventCount}
            webhookCount={webhookCount}
            onComplete={handleComplete}
          />
        </Stack>
      </Stack>
    </Container>
  );
  return <>{renderInstallation}</>;
}

InstallationWooCommerce.propTypes = {
  trackingTag: PropTypes.object,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  noPadding: PropTypes.bool,
  onRefresh: PropTypes.func,
  eventCount: PropTypes.number,
  webhookCount: PropTypes.number,
  dnsStatus: PropTypes.number,
  isDataRequiredToContinue: PropTypes.bool,
};
