import PropTypes from 'prop-types';
import { Typography, TextField, Box, Button } from '@mui/material';
import { Iconify } from '../landing';
import { useSnackbar } from '../snackbar';

export default function CopyScript({
  className,
  title,
  alias,
  description,
  descriptionSx,
  placeholder,
  script,
  rows,
  sx,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipboard = (string) => {
    document.getElementById(`script-${alias}`).select();
    document.execCommand('copy');
    const sel = document.getSelection();
    sel.removeAllRanges();

    enqueueSnackbar('Copied to clipboard.');
  };
  return (
    <Box sx={sx}>
      <Box display="flex" mb={1}>
        <Box>
          {title && (
            <Typography variant="h4" component="h2" gutterBottom>
              {title}
            </Typography>
          )}
          {description && <Typography variant="body1" sx={descriptionSx}>{description}</Typography>}
        </Box>
        <Box flexGrow={1} />
        <Button
          variant="contained"
          color="accent"
          size="small"
          sx={{ mt: -0.5, mb: 0.5, minWidth: 75 }}
          onClick={() => copyToClipboard(script)}
          startIcon={<Iconify icon="solar:copy-linear" />}
        >
          Copy
        </Button>
      </Box>
      <TextField
        id={`script-${alias}`}
        InputProps={{
          sx: {
            p: 0,
            overflow: 'hidden',
            backgroundColor: 'rgb(0, 0, 0, 0.04)',
            '& textarea': {
              fontFamily: 'Courier New',
              fontSize: '0.85rem',
              lineHeight: 1.2,
              px: 2,
              pt: 1.5,
              pb: 1,
              fontWeight: 600,
            },
          },
        }}
        sx={{ p: 0 }}
        fullWidth
        multiline
        rows={rows ?? 12}
        placeholder={placeholder ?? 'Code'}
        value={script}
        spellCheck="false"
      />
    </Box>
  );
}

CopyScript.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  alias: PropTypes.string.isRequired,
  description: PropTypes.string,
  descriptionSx: PropTypes.object,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  script: PropTypes.string,
  sx: PropTypes.object
};
