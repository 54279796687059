import ReactMarkdown from 'react-markdown';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { PrismicRichText } from '@prismicio/react';
import Stack from '@mui/material/Stack';
import typography from '../../theme/landing/typography';
import { parseIdFromString } from '../../utils/general';
import Link from '../link/link';

PrismicTypography.propTypes = {
  children: PropTypes.any,
  sx: PropTypes.object,
  variant: PropTypes.string,
  colorVariant: PropTypes.string,
};

export default function PrismicTypography({ children, sx, variant, colorVariant, ...rest }) {
  if (!children) {
    return null;
  }

  const isString = typeof children === 'string' || children instanceof String;

  const strongSx = ['h1', 'h2', 'h3', 'h4', 'h5', 'subtitle1', 'subtitle2'].includes(variant)
    ? { fontWeight: 'inherit', color: 'accent.main', fontSize: '102%' }
    : { fontWeight: 600 };

  const colorSx = colorVariant === 'dark' ? { color: 'common.white' } : {};

  const renderString = (content) => {
    const { id, text } = parseIdFromString(content);

    return (
      <Typography
        sx={{
          ...colorSx,
          ...{
            whiteSpace: { xs: 'normal', md: 'pre-wrap' },
            '& p': { my: 0 },
            '& strong': strongSx,
          },
          ...sx,
        }}
        variant={variant}
        id={id}
        {...rest}
      >
        <ReactMarkdown
          components={{
            p: Fragment,
          }}
        >
          {text}
        </ReactMarkdown>
      </Typography>
    );
  };

  const renderRichText = (content) => (
    <Stack
      spacing={{ xs: 1, md: 1.5 }}
      sx={{
        ...colorSx,
        ...{
          '& p': { my: 0 },
          '& p strong, & li strong': strongSx,
          '& h1, & h2, & h3, & h4': {
            marginBottom: '0.75rem',
            marginTop: '1.5rem',
          },
          '& h1:first-child, & h2:first-child, & h3:first-child, & h4:first-child': {
            marginTop: 0,
          },
          '& h1': typography.h1,
          '& h2': typography.h2,
          '& h3': typography.h3,
          '& h4': typography.h4,
          '& a': {
            color: 'accent.main',
            fontWeight: 500,
            textDecoration: 'underline',
          },
          '& pre': {
            whiteSpace: 'pre-wrap',
            px: 2.5,
            py: 1.5,
            borderRadius: 1.5,
            backgroundColor:
              colorVariant === 'dark'
                ? 'hsl(240deg 12.3% 49.21% / 21%)'
                : 'hsl(214.29deg 24.14% 11.37% / 5.1%)',
            border:
              colorVariant === 'dark'
                ? '1px dashed rgba(145, 158, 171, 0.3)'
                : '1px dashed hsl(214.29deg 24.14% 11.37% / 34.9%)',
          },
          '& li': {
            marginBottom: '0.5rem',
          },
          '& li:last-child': {
            marginBottom: 0,
          },
        },
        ...sx,
      }}
      {...rest}
    >
      <PrismicRichText
        field={content}
        components={{
          heading1: ({ text: textRaw }) => {
            const { id, text } = parseIdFromString(textRaw);
            return (
              <Typography variant="h2" id={id}>
                {text}
              </Typography>
            );
          },
          heading2: ({ text: textRaw }) => {
            const { id, text } = parseIdFromString(textRaw);
            return (
              <Typography variant="h2" id={id}>
                {text}
              </Typography>
            );
          },
          heading3: ({ text: textRaw }) => {
            const { id, text } = parseIdFromString(textRaw);
            return (
              <Typography variant="h3" id={id}>
                {text}
              </Typography>
            );
          },
          heading4: ({ text: textRaw }) => {
            const { id, text } = parseIdFromString(textRaw);
            return (
              <Typography variant="h4" id={id}>
                {text}
              </Typography>
            );
          },
          heading5: ({ text: textRaw }) => {
            const { id, text } = parseIdFromString(textRaw);
            return (
              <Typography variant="h5" id={id}>
                {text}
              </Typography>
            );
          },
          image: ({ node, key }) => {
            const { id, text } = parseIdFromString(node.alt ?? '');
            const img = (
              <Box
                component="img"
                src={node.url}
                alt={text}
                data-copyright={node.copyright ? node.copyright : undefined}
                sx={{ borderRadius: 2, overflow: 'hidden' }}
              />
            );

            return (
              <Box
                key={key}
                className="block-img"
                sx={{
                  ...(id === 'center' && { display: 'flex', justifyContent: 'center' }),
                }}
              >
                {node.linkTo ? (
                  <Link href={node.linkTo.url} target="_blank">
                    {img}
                  </Link>
                ) : (
                  img
                )}
              </Box>
            );
          },
        }}
      />
    </Stack>
  );

  return isString ? renderString(children) : renderRichText(children);
}
